$delay: .2s;
$speed: .5s;

.header {
	position: relative;
	padding: 12px 0;
	min-height: 10px;
	transition: min-height $speed $delay,padding $speed $delay,background-color $speed $delay,box-shadow $speed $delay;
	-webkit-font-smoothing: antialiased;
	z-index: 99;
	.row {
		margin: 0 -10px;
		width: initial;
		max-width: initial;
	}
	&__overflow-scroll {
		overflow: scroll;
		max-height: 400px;
	}
	.top-menu-wrapper {
		margin: 0 -10px;
	}
	.custom-dropdown-item {
		max-height: 340px;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-top: 0;
		padding-bottom: 0;
		@include media-breakpoint-down(md) {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}
	}
	.dropdown-link {
		padding-right: 1.2rem !important;
	}
	.navbar-collapse {
		@include media-breakpoint-down(md) {
			overflow: scroll;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 40px;
			height: calc(100vh - 125px);
		}
	}
	.cases__row {
		display: none;
		@include media-breakpoint-down(md) {
			display: none !important;
		}
		a {
			text-decoration: none;
		}
		a:hover p.color-white {
				color: #fecc2f;
		}
		p.color-white {
				color: white;
				margin-bottom: 0;
				transition: 0.2s ease all;
				@include media-breakpoint-down(xl) {
					font-size: 14px;
				}
				&.bold-text {
						margin-bottom: 20px;
						font-weight: bold;
				}
		}
	}
	&.fixed {
		position: fixed;
		width: 100%;
	}
	&.all-black-bg {
		background-color: rgba(0,0,0,0.4);
		background-image: url(./../picture/backgrounds/topographic-transparent-01.png);
		box-shadow: 0 15px 15px rgba(0, 0, 0, 0.25);
		transition: all 0s 0s, min-height .2s;
	}
	&.expand {
			background-image: url(./../picture/backgrounds/topographic-transparent-01.png);
			box-shadow: 0 15px 15px rgba(0, 0, 0, 0.25);
			transition: all 0s 0s, min-height .2s;
			min-height: 400px;
			background-color: rgba(0,0,0,0.4);
			&.header {
					&_right-dotted {
							&:after {
									opacity: .4;
							}
					}
					&_cube {
							&:before {
									opacity: 1;
							}
					}
					&_left-dotted, &_cube-left-dotted {
							&:before {
									opacity: .4;
							}
					}
			}
			.header__cases {
					max-height: 350px;
					transition: $speed 0s;
					@include media-breakpoint-down(xl) {
							opacity: 1;
					}
			}
	}
	&_floating {
		position: fixed;
		top: 0;
		left: 0;
		margin-top: 0;
		padding: 12px 0;
		width: 100%;
		background-color: rgba(0,0,0,0.4);
		background-image: url(./../picture/backgrounds/topographic-transparent-02.png);
		background-repeat: repeat;
		box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
		z-index: 99;
		.navbar-expand-lg .navbar-nav .nav-link {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}
		.navbar-brand img {
			max-width: 106px;
			transition: 0.3s ease all;
		}
	}
}
.header, .footer, .icon:before {
	-webkit-font-smoothing: antialiased;
}