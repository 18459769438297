input.valid {
	border-color: green !important;
	color: green !important;
	&::placeholder {
		color: green !important;
	}
}
input.error {
	border-color: red !important;
	color: red !important;
	&::placeholder {
		color: red !important;
	}
}
.form {
	box-shadow: 0 15px 25px rgba(0,0,0,.15);
}