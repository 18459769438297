@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("./../pictures/upload/ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("./../fonts/slick.eot");
        src: slick-font-url("./../fonts/slick.eot?#iefix") format("embedded-opentype"), slick-font-url("./../fonts/slick.woff") format("woff"), slick-font-url("./../fonts/slick.ttf") format("truetype"), slick-font-url("./../fonts/slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick .slick-next {
	right: auto;
	left: 105px;
	bottom: -32px;
	transform: rotate(-90deg);
	top: auto;
	z-index: 10;
}

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 33px;
    width: 26px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
		outline: none;
		svg path {
			transition: 0.3s ease all;
		}
    &:hover, &:focus {
        outline: none;
        background: transparent;
				color: transparent;
				svg path {
					//fill: white;
				}
    }
}
.slick-white .slick-dots {
	bottom: 55px;
	right: 150px;
}
.slick-white .slick-next {
	right: 110px;
	transform: rotate(-90deg);
	top: auto;
	bottom: 55px;
}
.slick-white .slick-dots li button {
	background-color: white;
}
.slick-white .slick-dots li button:before {
	left: -4px;
}

.slick-prev {
    display: none !important;
    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: -25px;
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }
}

/* Dots */

.slick-dotted.slick-slider {
		margin-bottom: 30px;
		padding-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -30px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            background-color: black;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            position: relative;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                bottom: 4px;
                left: 4px;
                width: 20px;
                height: 20px;
                //background-image: linear-gradient(to top, #FECC2F 50%, transparent 50% 100%);
                //background-size: 100% 100%;
                //background-repeat: no-repeat;
                animation-timing-function: ease;
                animation-iteration-count: 1;
                animation-duration: inherit;
                animation-fill-mode: forwards;
                background-color: #FECC2F;
                height: 0px;
                animation-duration: 4s; //при изменении скорости переключения -менять это значение
                content: '';
                opacity: 0;
            }
        }
        &.slick-active button:before {
            opacity: 1;
            animation-name: grad;
        }
    }
}
.header-slider {
	.slick-next {
		right: auto;
		left: 2px;
		top: 245px;
		bottom: auto;
		animation: fadeIn .5s 1s ease-in-out;
		opacity: 0;
		animation-fill-mode: forwards;
	}
	.slick-dots {
		bottom: auto;
		top: 120px;
		display: flex;
		flex-direction: column;
		animation: fadeIn .5s 1s ease-in-out;
		opacity: 0;
		animation-fill-mode: forwards;
	}
}
@keyframes grad {
    0%{height:0;}
    100%{height: 100%;}
    //100%{background-position:0% 0%}
}
@media (max-width: 991px) {
	.header-slider .slick-dots,
	.header-slider .slick-next {
		opacity: 0;
		pointer-events: none;
		animation: none;
	}
}
@media (max-width: 767px) {
	.header-slider .slick-dots,
	.header-slider .slick-next {
		animation: fadeIn .5s 1s ease-in-out;
		opacity: 0;
		animation-fill-mode: forwards;
		pointer-events: inherit;
	}
	.header-slider .slick-dots {
		bottom: 60px;
		top: auto;
		left: 0;
		right: auto;
		flex-direction: row;
	}
	.header-slider .slick-next {
		bottom: 60px;
		top: auto;
		left: 110px;
		right: auto;
		transform: rotate(-90deg);
	}
}
@media (max-width: 767px) {
    .slick-dots li button:before {
        left: -4px;
		}
		.what-happened .mobile_slider .slick-dots {
			left: 15px;
		}
    .mobile_slider .slick-next {
        right: auto;
        left: 265px;
        bottom: -32px;
        transform: rotate(-90deg);
        top: auto;
        z-index: 10;
    }
    .slider-module .slick-dots {
        left: -10px;
    }
    .mobile_slider .slick-next svg path {
        fill: black;
    }
    .need-system__bubbles.mobile_slider .slick-next {
        left: calc(50% + 70px);
    }
}
